<template>
  <v-bottom-navigation v-show="false" v-if="$vuetify.breakpoint.smAndDown" style="z-index: 199;" class="pb-3 fonte vitrify-content elevation-0" background-color="transparent" color="transparent" bottom fixed>
    <v-flex class="px-1" xs12>
      <div class="expande-horizontal centraliza">
        <v-text-field
          rounded
          hide-details
          background-color="#f7f7f7"
          :color="$theme.primary"
          prepend-inner-icon="mdi-comment"
          :disabled="loading"
          v-model="comment"
          solo
          @keyup.enter.prevent="sendComment"
          @click:append="sendComment"
          placeholder="Escreva um comentário..."
        ></v-text-field>
        <v-avatar color="blue darken-1" class="elevation-2 ml-2">
          <v-icon color="#f7f7f7">mdi-send</v-icon>
        </v-avatar>
      </div>
    </v-flex>
  </v-bottom-navigation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      tab: 0,
      comment: "",
      loading: false,
      show_movimentation: false
    };
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  filters: {
    firstName(val) {
      if (!val.nome) return "";
      return val.nome.split(" ")[0] || "";
    }
  },
  methods: {
    ...mapActions([
      "createGlobalMessage",
      "listar_gestordepedidos",
      "abre_modal_view_gestordepedido",
      "fecha_modal_view_gestordepedido"
    ]),
    showMovimentation() {
      this.show_movimentation = !this.show_movimentation;
    },
    sendComment() {
      if (this.comment.length > 0) {
        this.loading = true;
        this.$run("transacoes/make-comment", {
          _id: this.get_gestordepedido._id,
          comment: this.comment
        })
          .then(res => {
            this.loading = false;
            this.comment = "";
            this.listar_gestordepedidos(true);
            let sale = res.data;
            sale.comment = sale.comments ? sale.comments.reverse() : [];
            this.$store.commit("set_gestordepedido", sale);
          })
          .catch(e => {
            this.loading = false;
            this.createGlobalMessage({
              type: "error",
              text: e.message
            });
          });
      }
    }
  }
};
</script>
