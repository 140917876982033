<template>
  <div
    style="flex-wrap: nowrap;"
    class="windows-style expande-horizontal centraliza fonte"
  >
    <v-list
      color="transparent"
      :dark="$theme.dark"
      class="fonte pa-0 ma-0"
      style="max-width: 94%;"
      dense
      nav
    >
      <v-list-item class="px-3 mx-0">
        <v-avatar class="mr-2" color="#e5e5e5" size="36">
          <v-icon :color="$theme.primary">mdi-network-pos</v-icon>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="">
            <span class="fonte">
              {{
                get_gestordepedido.compra.cliente
                  ? get_gestordepedido.compra.cliente.nome
                  : "Cliente não informado"
              }}
            </span>
          </v-list-item-title>
          <span :style="`color: ${$theme.primary}`">
            <span class="fonteMini">
              {{ get_gestordepedido.tipo }} #{{
                get_gestordepedido.id_transacao
              }}
            </span>
            <span class="fonteMini grey--text">
              Solicitado
              {{
                $moment(get_gestordepedido.created_at).format(
                  "DD/MM/YY [às] HH:mm"
                )
              }}.
            </span>
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
    <v-avatar
      @click="fechaConsulta"
      style="cursor: pointer;"
      size="27"
      :color="$theme.primary"
      class="mr-2"
      icon
    >
      <v-icon color="#333">mdi-close</v-icon>
    </v-avatar>
    <!-- <v-tooltip
      v-if="get_gestordepedido.status_do_pedido !== 'concluído'"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="get_gestordepedido.status_do_pedido !== 'concluído'"
          @click="confirmCancel"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="red">mdi-file-cancel-outline</v-icon>
        </v-btn>
      </template>
      <span>Clique para cancelar a venda</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="printReceipt(get_gestordepedido)"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :color="$theme.primary">mdi-cloud-print-outline</v-icon>
        </v-btn>
      </template>
      <span>Clique para imprimir o orçamento</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="configureDeliveryNote(get_gestordepedido)"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :color="$theme.third">mdi-cloud-print-outline</v-icon>
        </v-btn>
      </template>
      <span>Clique para imprimir a nota de entrega</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="openReceiptEmitter" icon v-bind="attrs" v-on="on">
          <v-icon :color="$theme.third">mdi-cash</v-icon>
        </v-btn>
      </template>
      <span>Clique para imprimir um recibo</span>
    </v-tooltip> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "openReceiptEmitter",
    "printReceipt",
    "configureDeliveryNote",
    "confirmCancel"
  ],
  computed: {
    ...mapGetters(["get_gestordepedido", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["fecha_modal_view_gestordepedido"]),
    fechaConsulta() {
      this.fecha_modal_view_gestordepedido();
      if (this.get_gestordepedido.status_do_pedido === 'attending') {
        this.$emit('closeConsult');
      } else {
        if (this.getLoggedUser.is_patient) {
          this.$router.push('/dashboard')
        } else {
          this.$router.push('/medico')
        }
      }
    }
  }
};
</script>
