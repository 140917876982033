<template>
  <v-dialog
    transition="slide-x-transition"
    fullscreen
    persistent
    class="elevation-0"
    v-model="get_modal_view_gestordepedido"
  >
    <div
      style="min-height: 100vh;background-color: rgba(0,0,0,0.6);"
      class="expande-horizontal centraliza cool-shaddow"
    >
      <v-flex xs12 md10>
        <sale-header
          :openReceiptEmitter="openReceiptEmitter"
          :printReceipt="printReceipt"
          :configureDeliveryNote="configureDeliveryNote"
          :confirmCancel="confirmCancel"
          @closeConsult="$refs.consultRoom.closeCall()"
        />
        <v-flex xs12>
          <div class="expande-horizontal wrap">
            <v-flex xs12 md8>
              <ConsultRoom ref="consultRoom" />
            </v-flex>
            <v-flex xs12 md4>
              <sale-body
                :openReceiptEmitter="openReceiptEmitter"
                :printReceipt="printReceipt"
                :configureDeliveryNote="configureDeliveryNote"
                :confirmCancel="confirmCancel"
                :sendToPdv="sendToPdv"
                :initPayment="initPayment"
              />
            </v-flex>
          </div>
        </v-flex>
        <SaleInput />
        
        <!-- <delivery-note-to-print 
          v-show="false"
          id="print-note"
          class="print-unbreak"
          :lastSell="lastSell" 
          :getLoggedUser="getLoggedUser" 
        /> -->
      </v-flex>
    </div>
    <ModalFechamento
      :payment_sys_config="payment_sys_config"
      :updatePayment="updatePayment"
      ref="modalFechamento"
    />
    <v-dialog v-model="emit_receipt_dialog" width="600">
      <v-card class="expande-horizontal wrap">
        <v-flex xs12>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                :color="$theme.secondary"
                @click="closeReceiptEmitter"
              >
                <v-icon color="#fff">
                  mdi-arrow-left
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  Informações do recibo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-flex>
        <v-flex xs12>
          <v-form ref="formReceipt">
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="cliente"
                v-model="emit_receipt_form.client"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Valor"
                v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##']"
                v-model="emit_receipt_form.value"
                outlined
                dense
                hide-details
                autofocus
                @input="formatNumberToExtense"
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Valor por extenso"
                v-model="emit_receipt_form.value_extense"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Referente a"
                v-model="emit_receipt_form.referrer_to"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pt" xs12>
              <v-btn :color="$theme.secondary" dark @click="emitReceipt">
                Emitir
              </v-btn>
            </v-flex>
          </v-form>
        </v-flex>
      </v-card>
    </v-dialog>
    <ModalChangeAddressFromViewSale
      :printDeliveryNote="printDeliveryNote"
      ref="modalChangeDelivery"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalFechamento from "../components/SaleStages/ModalFechamento.vue";
import ModalChangeAddressFromViewSale from "@/apps/27#Caixas/views/components/ModalChangeDeliveryFromViewSale.vue";
import printHtmlToPDF from "print-html-to-pdf";
import Moment from "moment";
import QrcodeVue from "qrcode.vue";
import extenso from "extenso";
import CosmosPluginStore from "@/apps/CosmosPluginStore/index.vue";
import SaleHeader from "@/apps/GestorDePedido/components/viewSale/saleHeader.vue";
import SaleBody from "@/apps/GestorDePedido/components/viewSale/saleBody.vue";
import SaleInput from "@/apps/GestorDePedido/components/viewSale/SaleInput.vue";
import deliveryNoteToPrint from "@/apps/GestorDePedido/components/viewSale/documentToPrint/deliveryNote.vue";
import ConsultRoom from "@/apps/GestorDePedido/components/consultRoom.vue";
import { EventBus } from "@/main.js";

export default {
  props: {
    payment_sys_config: {
      type: Object,
      default: () => {}
    },
    receipt: {
      type: Object,
      default: () => {
        return {
          show_my_address: { active: true },
          show_my_logo: { active: true },
          show_my_qrcode: { active: true },
          show_client_details: { active: true },
          show_header_text: { active: true, text: "" },
          show_footer_text: { active: true, text: "" }
        };
      }
    }
  },
  data() {
    return {
      tab: 0,
      moment: Moment,
      printHtmlToPDF: printHtmlToPDF,
      lastSell: {
        compra: {
          preco_total: 0,
          cliente: {},
          address: {}
        },
        caixa: {
          funcionario: {}
        }
      },
      emit_receipt_form: {
        client: {},
        vendedor: this.getLoggedUser,
        value: ""
      },
      emit_receipt_dialog: false,
      in_printing: false,
      manual_select_status: false
    };
  },
  components: {
    ModalFechamento,
    QrcodeVue,
    ModalChangeAddressFromViewSale,
    CosmosPluginStore,
    SaleHeader,
    SaleBody,
    deliveryNoteToPrint,
    ConsultRoom,
    SaleInput
  },
  computed: {
    ...mapGetters([
      "get_gestordepedidos",
      "get_gestordepedido",
      "get_modal_view_gestordepedido",
      "getLoggedUser"
    ]),
    logoBase64() {
      if (this.getLoggedUser.is_patient) {
        return ''
      } else {
        return this.getLoggedUser.tenant[0].profileImage;
      }
    },
    nomeCliente() {
      if (
        this.get_gestordepedido.compra.cliente &&
        this.get_gestordepedido.compra.cliente.nome
      ) {
        return this.get_gestordepedido.compra.cliente.nome;
      } else {
        return "Cliente não informado!";
      }
    }
  },
  filters: {
    getPriceFilter(val) {
      let price = 0;
      if (val.tipo_de_preco === "unico") {
        price = val.preco_unico;
        return price;
      } else if (val.tipo_de_preco === "multiplo") {
        const precoEscolhido = val.preco_multiplo.filter(preco => preco.marked);
        price = precoEscolhido[0].preco;
        return price;
      }
      return price;
    }
  },
  methods: {
    confirmCancel() {
      this.createConfirmAction({
        message: "Deseja cancelar esta venda?",
        icone: "mdi-close-circle",
        isScoped: true,
        action: () => this.cancel_sale(),
        action_value: "ok"
      });
    },
    sendToPdv(id) {
      this.fecha_modal_view_gestordepedido();
      this.$router.push(`/atendimento?t_id=${id}`);
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_gestordepedido)
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.step = 3;
          } else {
            this.step = 1;
          }
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    initPayment() {
      //validations here pls
      if (this.get_gestordepedido.compra.produtos.length) {
        if (!this.$refs.modalFechamento.modal) {
          this.$refs.modalFechamento.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma venda sem produtos!",
          timeout: 6000
        });
      }
    },
    ...mapActions([
      "criar_gestordepedido",
      "createConfirmAction",
      "atualizar_gestordepedido",
      "listar_gestordepedidos",
      "fecha_modal_view_gestordepedido"
    ]),
    update_status(index, status_to_find_next) {
      let status;

      if (status_to_find_next) {
        this.status_type[this.get_gestordepedido.compra.tipo].map((s, i) => {
          if (status_to_find_next === s.value) {
            status = this.status_type[this.get_gestordepedido.compra.tipo][
              i + 1
            ];
          }
        });
      } else {
        status = this.status_type[this.get_gestordepedido.compra.tipo][index];
      }
      // console.log("sss", status);
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = status.value;
          this.get_gestordepedido.historico_de_status.push({
            status: status.value,
            data: new Date()
          });
          const docs = this.get_gestordepedidos.docs;
          const updatedDoc = this.get_gestordepedido;
          const newDocs = [];
          docs.map(docTransaction => {
            if (docTransaction._id === updatedDoc._id) {
              newDocs.push(this.get_gestordepedido);
            } else {
              newDocs.push(docTransaction);
            }
          });
          this.$store.commit("set_gestordepedidos", {
            ...this.get_gestordepedidos,
            docs: newDocs
          });
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    refuse_sale() {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: "recusada"
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `A venda foi recusada`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = "recusada";
          this.get_gestordepedido.historico_de_status.push({
            status: "recusada",
            data: new Date()
          });
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    remove_sale_from_localstorage(to_remove_id) {
      if (!localStorage.gestordepedidos) return;
      const docs = JSON.parse(localStorage.gestordepedidos);
      const newDocs = docs.docs.filter(docTransaction => {
        return docTransaction._id !== to_remove_id;
      });
      localStorage.setItem(
        "gestordepedidos",
        JSON.stringify({ docs: newDocs })
      );
      this.listar_gestordepedidos();
    },
    cancel_sale() {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: "cancelada"
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `A venda foi cancelada`,
            timeout: 6000
          });
          // this.get_gestordepedido.status_do_pedido = "cancelada";
          // this.get_gestordepedido.historico_de_status.push({
          //   status: "cancelada",
          //   data: new Date()
          // });
          this.fecha_modal_view_gestordepedido();
          this.remove_sale_from_localstorage(this.get_gestordepedido._id);
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    printReceipt(compra) {
      this.in_printing = true;
      this.lastSell = compra;
      var janelaImprimir = window.open("", "", "width=1060,height=700");
      setTimeout(() => {
        janelaImprimir.document.open();
        janelaImprimir.document.write(
          "<html><head><title>Imprimir</title>"
        );
        janelaImprimir.document.write("<style>");
        janelaImprimir.document.write("table { width: 100%; }");
        janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; }");
        janelaImprimir.document.write(".tr-table { border: 1px solid #333; margin: 0px; }");
        janelaImprimir.document.write(".bg-texts { background-color: #ffaf15 !important; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px; color: #333; }");
        janelaImprimir.document.write("@page { size: A4; margin: 2cm; }");
        janelaImprimir.document.write("@media print { .bg-texts { background-color: #ffaf15 !important; } }");
        janelaImprimir.document.write("</style>");
        janelaImprimir.document.write(
          "</head><body>"
        );
        janelaImprimir.document.write('<div id="imprimir-conteudo">');
        janelaImprimir.document.write(
          document.getElementById("print-html").innerHTML
        );
        janelaImprimir.document.write("</div>");
        janelaImprimir.document.write("</body></html>");
        janelaImprimir.document.close();
        janelaImprimir.focus(); // necessary for IE >= 10*/
        janelaImprimir.print();
        if (this.$vuetify.breakpoint.lgAndUp) {
          janelaImprimir.close();
        }
      }, 200);
    },
    configureDeliveryNote() {
      this.$refs.modalChangeDelivery.open();
    },
    printDeliveryNote(compra) {
      this.lastSell = compra;
      var janelaImprimir = window.open("", "", "width=1060,height=700");
      setTimeout(() => {
        janelaImprimir.document.open();
        janelaImprimir.document.write(
          "<html><head><title>Nota de entrega</title>"
        );
        janelaImprimir.document.write("<style>");
        janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; width: 100%; display: flex; flex-direction: row; flex-wrap: nowrap; }");
        janelaImprimir.document.write(".page-unbreak { page-break-after: always; background: #333;width: 1400px; display: flex;  flex-wrap: nowrap !important; }");
        janelaImprimir.document.write("@page { size: landscape; margin: 1cm; display: flex; flex-wrap: nowrap; }");
        janelaImprimir.document.write("</style>");
        janelaImprimir.document.write(
          "</head><body>"
        );
        janelaImprimir.document.write(
          document.getElementById("print-note").innerHTML
        );
        janelaImprimir.document.write("</body></html>");
        janelaImprimir.document.close();
        janelaImprimir.focus(); // necessary for IE >= 10*/
        janelaImprimir.print();
        if (this.$vuetify.breakpoint.lgAndUp) {
          janelaImprimir.close();
        }
      }, 200);
    },
    openModalAddProduct(item, index) {
      console.log("abre modal product", item);
      return;
    },
    abre_modal_view_caixa(item) {
      console.log("abre modal view caixa", item);
      return;
    },
    openReceiptEmitter() {
      this.emit_receipt_dialog = true;
      this.emit_receipt_form = {
        client: this.get_gestordepedido.compra.cliente.nome,
        value: ""
      };
    },
    closeReceiptEmitter() {
      this.emit_receipt_dialog = false;
    },
    formatNumberToExtense() {
      const value = this.emit_receipt_form.value || "0";
      const value_replaced = parseInt(value.replace(",", "."));
      this.emit_receipt_form.value_extense = extenso(value_replaced, {
        mode: "currency",
        currency: { type: "BRL" }
      });
      this.$forceUpdate();
    },
    emitReceipt() {
      if (this.$refs.formReceipt.validate()) {
        this.getNextOrderReceipt();
        var janelaImprimir = window.open("", "", "width=1060,height=700");
        setTimeout(() => {
          janelaImprimir.document.open();
          janelaImprimir.document.write(
            "<html><head><title>Recibo</title>"
          );
          janelaImprimir.document.write("<style>");
          janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; width: 100%; display: flex; flex-direction: row; flex-wrap: nowrap; }");
          janelaImprimir.document.write(".page-unbreak { page-break-after: always; background: #333;width: 1400px; display: flex;  flex-wrap: nowrap !important; }");
          janelaImprimir.document.write("@page { size: landscape; margin: 1cm; display: flex; flex-wrap: nowrap; }");
          janelaImprimir.document.write("</style>");
          janelaImprimir.document.write(
            "</head><body>"
          );
          janelaImprimir.document.write(
            document.getElementById("print-receipt").innerHTML
          );
          janelaImprimir.document.write("</body></html>");
          janelaImprimir.document.close();
          janelaImprimir.focus(); // necessary for IE >= 10*/
          janelaImprimir.print();
          if (this.$vuetify.breakpoint.lgAndUp) {
            janelaImprimir.close();
          }
        }, 200);
        // this.$refs.emitterReceipt.generatePdf();
        setTimeout(() => {
          this.closeReceiptEmitter();
        }, 4000);
        this.saveSale();
      }
    },
    getNextOrderReceipt() {
      const receipts = this.get_gestordepedido.compra.receipts
        ? this.get_gestordepedido.compra.receipts
        : [];
      if (receipts.length === 0) {
        let compra = this.get_gestordepedido.compra;
        compra.receipts = [];
        this.emit_receipt_form.order = receipts.length + 1;
        this.emit_receipt_form.data = this.$moment().format("LLLL");
        compra.receipts.push(this.emit_receipt_form);
        this.$store.commit("set_gestordepedido", {
          ...this.get_gestordepedido,
          compra
        });
      }
      if (receipts.length >= 1) {
        let compra = this.get_gestordepedido.compra;
        this.emit_receipt_form.order = receipts.length + 1;
        this.emit_receipt_form.data = this.$moment().format("LLLL");
        compra.receipts.push(this.emit_receipt_form);
        this.$store.commit("set_gestordepedido", {
          ...this.get_gestordepedido,
          compra
        });
      }
    },
    saveSale() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    }
  },
  async mounted() {
    
  }
};
</script>

<style>
.border-view-sale {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}
.roller {
  font-family: "Prompt", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 13px;
  color: rgb(69, 200, 111);
}
.item-current-status {
  background: #1173ff;
  color: #fff !important;
}
.border-receipt {
  border: 10px solid #f2f2f2;
  border-radius: 5px;
}
</style>
