<template>
  <div
    v-show="false"
    class="print-unbreak"
    style="page-break-after: always !important; width: 100% !important; display: flex !important;  flex-wrap: nowrap !important;"
  >
    <div
      style="width: 50%; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
    >
      <div
        style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
      >
        <div
          style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
        >
          <img :style="`height: 50px;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
        </div>
        <div
          style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
        >
        <strong style="text-align: center; font-size: 9pt;">
            {{ getLoggedUser.tenant[0].nome }}
          </strong>
          <span
            style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
          >
            CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
          </span>
          <span
            v-if="getLoggedUser.tenant[0].address"
            style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
          >
            {{ getLoggedUser.tenant[0].address.formatted_address }}
          </span>
          <span
            v-if="getLoggedUser.tenant[0].phone"
            style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
          >
            FONE: {{ getLoggedUser.tenant[0].phone }}
          </span>
        </div>
        <div
          style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
        >
        </div>
      </div>
      <div
        class="mt-3"
        style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
      >
        <h3>NOTA DE ENTREGA #{{ lastSell.id_transacao }}</h3>
        <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
      </div>
      <div
        style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
      ></div>
      <div
        style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
      >
        <div v-if="lastSell.compra.cliente">
          <strong style="margin-right: 6px; margin-bottom: 6px;">
            Cliente:
          </strong>
          {{ lastSell.compra.cliente.nome }}
        </div>
        <div v-if="lastSell.compra.address">
          <strong style="margin-right: 6px;">
            Endereço:
          </strong>
          {{ lastSell.compra.address.street }},
          {{ lastSell.compra.address.district }} Nº
          {{ lastSell.compra.address.number }},
          {{ lastSell.compra.address.city }} -
          {{ lastSell.compra.address.state }}.
        </div>
      </div>
      <div
        style="margin-top: 6px; margin-bottom: 6px; padding: 12px;"
      >
        <v-simple-table
          dark
          style="min-witdh: 100%; background: #f0f0f0;"
          dense
        >
          <template>
            <thead>
              <tr
                style="background: #EBEBEB; border: 1px solid #f0f0f0;"
              >
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Quant
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Item
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Medida
                </td>
              </tr>
            </thead>
            <tbody>
              <template
                style="border-top: 1px solid #D2D4CF;"
                v-for="(item, i2) in lastSell.compra.produtos"
              >
                <template v-for="(ficha, i) in item.ficha_tecnica">
                  <tr
                    :class="{
                      'product-done': ficha.is_done
                    }"
                    style="background: #f0f0f0; border: none;"
                    :key="`item-ficha-prod-${i}-${i2}-`"
                    v-show="
                      ficha.quantity &&
                        ficha.quantity >= 1 &&
                        ficha.type == 'Peça Principal'
                    "
                  >
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.quantity }}
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #333;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.descricao ? ficha.descricao : "" }} -
                      {{ item.nome }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949;"
                      v-if="ficha.comprimento || ficha.largura"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.comprimento }} x {{ ficha.largura }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949;"
                      v-else
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      ##
                    </td>
                  </tr>
                </template>
                <tr
                  light
                  v-show="item.obs"
                  style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                  :key="(i + 1) / 6"
                  xs12
                >
                  <td
                    class="fonte fonte-micro"
                    style="color: #878686"
                  >
                    Obs:
                  </td>
                  <td
                    class="fonte fonte-micro"
                    style="color: #878686"
                  >
                    {{ item.obs }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="expande-horizontal wrap">
        <v-flex xs6>
          <div
            style="margin-top: 36px;"
            class="mt-6 expande-horizontal centraliza"
          >
            ________________________________
          </div>
          <div
            style="margin-bottom: 36px;"
            class="expande-horizontal mb-6 centraliza"
          >
            RECEBIDO POR
          </div>
        </v-flex>
        <v-flex xs6>
          <div
            style="margin-top: 36px;"
            class="mt-6 expande-horizontal centraliza"
          >
            ________________________________
          </div>
          <div
            style="margin-bottom: 36px;"
            class="expande-horizontal mb-6 centraliza"
          >
            <span class="font-uppercase">
              {{ lastSell.caixa.funcionario.nome }}
            </span>
          </div>
        </v-flex>
        <v-flex style="padding: 20px;" class="mt-6 pa-6" xs12>
          <span
            style="font-size: 9pt; color: #333; width: 90%"
            class="text-center"
          >
            OBS: FAVOR CONFERIR PEÇAS NO ATO DA ENTREGA. NÃO NOS
            RESPONSABILIZAMOS POR DANOS CAUSADOS APÓS A ENTREGA.
          </span>
        </v-flex>
      </div>
      <div
        style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
      ></div>
    </div>
    <div
      style="width: 50%; border-left: 1px dashed #333; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
    >
    <div
        style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
      >
        <div
          style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
        >
          <img :style="`height: 50px;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
        </div>
        <div
          style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
        >
        <strong style="text-align: center; font-size: 9pt;">
            {{ getLoggedUser.tenant[0].nome }}
          </strong>
          <span
            style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
          >
            CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
          </span>
          <span
            v-if="getLoggedUser.tenant[0].address"
            style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
          >
            {{ getLoggedUser.tenant[0].address.formatted_address }}
          </span>
          <span
            v-if="getLoggedUser.tenant[0].phone"
            style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
          >
            FONE: {{ getLoggedUser.tenant[0].phone }}
          </span>
        </div>
        <div
          style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
        >
        </div>
      </div>
      <div
        class="mt-3"
        style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
      >
        <h3>NOTA DE ENTREGA #{{ lastSell.id_transacao }}</h3>
        <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
      </div>
      <div
        style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
      ></div>
      <div
        style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
      >
        <div v-if="lastSell.compra.cliente">
          <strong style="margin-right: 6px; margin-bottom: 6px;">
            Cliente:
          </strong>
          {{ lastSell.compra.cliente.nome }}
        </div>
        <div v-if="lastSell.compra.address">
          <strong style="margin-right: 6px;">
            Endereço:
          </strong>
          {{ lastSell.compra.address.street }},
          {{ lastSell.compra.address.district }} Nº
          {{ lastSell.compra.address.number }},
          {{ lastSell.compra.address.city }} -
          {{ lastSell.compra.address.state }}.
        </div>
      </div>
      <div
        style="margin-top: 6px; margin-bottom: 6px; padding: 12px;"
      >
        <v-simple-table
          dark
          style="min-witdh: 100%; background: #f0f0f0;"
          dense
        >
          <template>
            <thead>
              <tr
                style="background: #EBEBEB; border: 1px solid #f0f0f0;"
              >
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Quant
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Item
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Medida
                </td>
              </tr>
            </thead>
            <tbody v-if="lastSell && lastSell.compra">
              <template
                style="border-top: 1px solid #D2D4CF;"
                v-for="(item, i2) in lastSell.compra.produtos"
              >
                <template v-for="(ficha, i) in item.ficha_tecnica">
                  <tr
                    :class="{
                      'product-done': ficha.is_done
                    }"
                    style="background: #f0f0f0; border: none;"
                    :key="`item-ficha-prod-${i2}-3`"
                    v-show="
                      ficha.quantity &&
                        ficha.quantity >= 1 &&
                        ficha.type == 'Peça Principal'
                    "
                  >
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.quantity }}
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #333;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.descricao ? ficha.descricao : "" }} -
                      {{ item.nome }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949;"
                      v-if="ficha.comprimento || ficha.largura"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.comprimento }} x {{ ficha.largura }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949;"
                      v-else
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      ##
                    </td>
                  </tr>
                </template>
                <tr
                  light
                  v-show="item.obs"
                  style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                  :key="(i + 1) / 6"
                  xs12
                >
                  <td
                    class="fonte fonte-micro"
                    style="color: #878686"
                  >
                    Obs:
                  </td>
                  <td
                    class="fonte fonte-micro"
                    style="color: #878686"
                  >
                    {{ item.obs }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="expande-horizontal wrap">
        <v-flex xs6>
          <div
            style="margin-top: 36px;"
            class="mt-6 expande-horizontal centraliza"
          >
            ________________________________
          </div>
          <div
            style="margin-bottom: 36px;"
            class="expande-horizontal mb-6 centraliza"
          >
            RECEBIDO POR
          </div>
        </v-flex>
        <v-flex xs6>
          <div
            style="margin-top: 36px;"
            class="mt-6 expande-horizontal centraliza"
          >
            ________________________________
          </div>
          <div
            style="margin-bottom: 36px;"
            class="expande-horizontal mb-6 centraliza"
          >
            <span v-if="lastSell && lastSell.caixa" class="font-uppercase">
              {{ lastSell.caixa.funcionario.nome }}
            </span>
          </div>
        </v-flex>
        <v-flex style="padding: 20px;" class="mt-6 pa-6" xs12>
          <span
            style="font-size: 9pt; color: #333; width: 90%"
            class="text-center"
          >
            OBS: FAVOR CONFERIR PEÇAS NO ATO DA ENTREGA. NÃO NOS
            RESPONSABILIZAMOS POR DANOS CAUSADOS APÓS A
            ENTREGA.</span
          >
        </v-flex>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['lastSell', 'getLoggedUser'],
    methods: {
      abre_modal_view_caixa(item) {
        // this.$emit('abre_modal_view_caixa', item)
      }
    }
  }
</script>