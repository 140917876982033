<template>
  <div>
    <basePlugin
      v-for="item in list"
      :item="item"
      :key="item._id"
      :classFonte="`fonteMini`"
    />
    <v-menu :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-tag-plus-outline</v-icon>
        </v-btn>
      </template>
      <v-card class="pb-3" width="380">
        <v-list nav dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weigth-bold fonte">
                Tags
              </v-list-item-title>
              <span class="fonte fonteMini mt-1 grey--text">
                Adicione tags para organizar melhor seus pedidos
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense nav>
          <v-list-item
            style="height: 20px;"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-checkbox
              x-small
              @click="
                !item.custom_value.active
                  ? removeTagFromSale(item)
                  : addTagOnSale(item)
              "
              v-model="item.custom_value.active"
            ></v-checkbox>
            <v-list-item-content
              class="white--text fonte"
              :style="
                `height: 40px; border-radius: 6px; padding-left: 6px; background: ${item.custom_value.color};`
              "
            >
              <v-list-item-title>{{
                item.custom_value.name
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="editTag(item)" small icon>
                <v-icon size="21">mdi-pencil-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div class="expande-horizontal mt-6">
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-3 " v-bind="attrs" v-on="on" icon>
                <div
                  class="pt-2"
                  style="display: flex; flex-direction: column;"
                >
                  <v-avatar
                    :size="23"
                    :color="formTag.custom_value.color"
                  ></v-avatar>
                  <span class="fonte fonteMini grey--text">cor</span>
                </div>
              </v-btn>
            </template>
            <v-color-picker
              dot-size="13"
              hide-inputs
              hide-mode-switch
              mode="hexa"
              v-model="formTag.custom_value.color"
              show-swatches
              swatches-max-height="173"
            ></v-color-picker>
          </v-menu>
          <v-text-field
            v-model="formTag.custom_value.name"
            placeholder="Nome da tag"
            dense
            rounded
            outlined
            class="ml-0 fonte"
            @keyup.enter.prevent="createOrUpdateTag"
            :hint="
              formTag._id
                ? 'você está editando uma tag'
                : 'você está criando uma nova tag'
            "
          />
          <v-btn class="mr-2" @click="createOrUpdateTag" icon>
            <v-icon :color="$theme.secondary">mdi-send</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import basePlugin from "../base.vue";
export default {
  props: {
    dataOrigin: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formTag: {
        custom_key: "tag_sale",
        custom_value: {
          name: "",
          color: this.$theme.secondary
        }
      },
      items: []
    };
  },
  methods: {
    ...mapActions(["atualizar_gestordepedido"]),
    editTag(tag) {
      this.formTag = tag;
    },
    closeEditTag() {
      this.formTag = {
        custom_key: "tag_sale",
        custom_value: {
          name: "",
          color: this.$theme.secondary
        }
      };
    },
    addTagOnSale(tag) {
      if (this.get_gestordepedido.compra.tags) {
        this.get_gestordepedido.compra.tags.push(tag);
      } else {
        this.get_gestordepedido.compra.tags = [tag];
      }
      this.atualizar_gestordepedido(true);
    },
    removeTagFromSale(tag) {
      this.get_gestordepedido.compra.tags = this.get_gestordepedido.compra.tags.filter(
        t => t._id !== tag._id
      );
      this.atualizar_gestordepedido(true);
    },
    createOrUpdateTag() {
      if (this.formTag._id) {
        this.$run("custom-key/update", this.formTag).then(res => {
          this.$store.dispatch("add_custom_key_in_indexeddb", [res.data]);
          this.items = this.items.map(item => {
            if (item._id === res.data._id) {
              return res.data;
            } else {
              return item;
            }
          });
          this.formTag = {
            custom_key: "tag_sale",
            custom_value: {
              name: "",
              color: this.$theme.secondary
            }
          };
        });
      } else {
        this.$run("custom-key/create", this.formTag).then(res => {
          this.$store.dispatch("add_custom_key_in_indexeddb", [res.data]);
          this.formTag = {
            custom_key: "tag_sale",
            custom_value: {
              name: "",
              color: this.$theme.secondary
            }
          };
          this.items.push(res.data);
        });
      }
    }
  },
  computed: {
    list() {
      return (
        this.dataOrigin ||
        [
          // {
          //   _id: "teste",
          //   custom_key: "tag_sale",
          //   custom_value: {
          //     name: "",
          //     color: "#8E24AA"
          //   }
          // }
        ]
      );
    },
    ...mapGetters(["get_gestordepedido"])
  },
  watch: {
    get_gestordepedido: {
      handler() {
        if (
          this.get_gestordepedido.compra &&
          Array.isArray(this.get_gestordepedido.compra.tags)
        ) {
          this.items = this.items.map(item => {
            if (
              this.get_gestordepedido.compra.tags &&
              this.get_gestordepedido.compra.tags.find(t => t._id === item._id)
            ) {
              item.custom_value.active = true;
              return item;
            } else {
              item.custom_value.active = false;
              return item;
            }
          });
        } else {
          this.items = this.items.map(item => {
            item.custom_value.active = false;
            return item;
          });
        }
      },
      deep: true
    }
  },
  components: {
    basePlugin
  },
  created() {
    this.$run("custom-key/read", ["tag_sale"]).then(res => {
      this.$store.dispatch("add_custom_key_in_indexeddb", res.data);
      if (
        this.get_gestordepedido.compra &&
        Array.isArray(this.get_gestordepedido.compra.tags)
      ) {
        this.items = res.data.map(item => {
          if (
            this.get_gestordepedido.compra.tags &&
            this.get_gestordepedido.compra.tags.find(t => t._id === item._id)
          ) {
            item.custom_value.active = true;
            return item;
          } else {
            item.custom_value.active = false;
            return item;
          }
        });
      } else {
        this.items = res.data;
      }
    });
  }
};
</script>

<style scoped>
.sale {
  width: 100%;
  height: 100%;
  background: #333;
}
</style>
