<template>
  <v-flex xs12>
    <div class="wrap expande-horizontal pa-2">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-flex xs12>
              <v-list nav dense class="px-0 mx-0" color="transparent">
                <v-list-item class="px-0 fonte mx-0">
                  <v-avatar class="mr-2" size="31" color="#333">
                    <v-icon size="20" :color="$theme.primary"
                      >mdi-basket-outline</v-icon
                    >
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Produtos
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ get_gestordepedido.compra.produtos.length }} ao todo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
          </div>
        </v-flex>

        <div
          :class="{ 'pl-7': $vuetify.breakpoint.lgAndUp }"
          class="expande-horizontal wrap pt-0 mb-2"
        >
          <v-flex xs12>
            <v-card
              v-for="(produto, index) in get_gestordepedido.compra.produtos"
              :key="`product-list-${index}`"
              color="#e5e5e5"
              outlined
              class="pt-0 mb-3"
            >
              <v-list class="pa-0 ma-0" color="transparent" dense>
                <v-list-item class="pa-0 ma-0 pl-1 px-3">
                  <v-avatar
                    size="31"
                    class="mr-2"
                    style="padding-left: 3px; padding-top: 2px;"
                    color="#e5e5e5"
                  >
                    <v-checkbox
                      @click="saveItemDone"
                      v-model="produto.is_done"
                      :color="$theme.primary"
                    />
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="fonte fonte-bold">
                      <span class="grey--text fonte fonteMini mr-1"
                        >Desc. da peça:</span
                      >
                      {{
                        produto.descricao_personalizada
                          ? produto.descricao_personalizada
                          : produto.nome
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte fonte-bold">
                      Deu
                      {{ $helper.formataSaldo(produto.seller_price || 0) }} com
                      {{ produto.nome }}.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-card-text style="background: #f2f2f2;">
                <v-list
                  class="fonte px-0 mx-0 pt-0 mt-0"
                  dense
                  nav
                  color="transparent"
                >
                  <template v-for="(item, i) in produto.ficha_tecnica">
                    <v-list-item
                      :key="`ficha-tecnica-${i}`"
                      @click="openModalAddProduct(produto, `${i}`)"
                      class="mt-0 pt-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="font-weight-bold"
                            >{{ item.quantity }}
                          </span>
                          <span>
                            {{ item.descricao }}
                          </span>
                          <span v-if="item.type !== 'Acabamento'">
                            <v-chip
                              :color="$theme.primary"
                              x-small
                              class="black--text"
                            >
                              {{ parseFloat(item.comprimento).toFixed(2) }} x
                              {{ parseFloat(item.largura).toFixed(2) }}
                              {{ verifyUnit(item) }}</v-chip
                            >
                          </span>
                          <v-chip
                            color="green"
                            x-small
                            rounded
                            class="mx-1 white--text"
                            >{{ $helper.formataSaldo(item.total || 0) }}</v-chip
                          >
                          <v-chip
                            :color="$theme.secondary"
                            x-small
                            class="white--text"
                            >{{ item.type }}</v-chip
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
            <!-- <v-simple-table style="min-witdh: 100%; overflow: auto;">
              <template v-slot:default>
                <thead style="background: #EBEBEB;">
                  <tr style="background: ">
                    <td class="fonte" style="color: #878686;">
                      Item
                    </td>
                    <td class="fonte" style="color: #878686;">
                      Medida
                    </td>
                    <td class="fonte" style="color: #878686;">
                      Total
                    </td>
                    <td class="fonte" style="color: #878686;">
                      <v-icon :color="$theme.secondary" size="20"
                        >mdi-tooltip-check-outline</v-icon
                      >
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(item_e, _i) in get_gestordepedido.compra.produtos"
                  >
                    <template v-for="(ficha, i) in item_e.ficha_tecnica">
                      <tr
                        :class="{
                          'product-done': ficha.is_done
                        }"
                        @click="openModalAddProduct(item, `${_i}`)"
                        style="min-width: 100%; background: #f0f0f0; border: none; cursor: pointer;"
                        :key="`item-ficha-prod-${_i}-${i}`"
                        v-show="ficha.quantity && ficha.quantity >= 1"
                      >
                        <td
                          style="color: #878686;"
                          @click.prevent="abre_modal_view_caixa(item)"
                        >
                          <span class="fonte fonteMini">
                            {{ ficha.quantity }} -
                            {{ ficha.descricao ? ficha.descricao : "" }}
                            - {{ item_e.nome }}
                          </span>
                        </td>
                        <td
                          v-if="ficha.comprimento || ficha.largura"
                          @click.prevent="abre_modal_view_caixa(item)"
                        >
                          <span
                            class="fonte blue--text"
                            style="word-break: keep-all;"
                          >
                            {{ ficha.comprimento }} x
                            {{ ficha.largura }}
                            {{ verifyUnit(ficha) }}
                          </span>
                        </td>
                        <td v-else @click.prevent="abre_modal_view_caixa(item)">
                          {{ ficha.quantity }}
                          {{ verifyUnit(ficha) }}
                        </td>
                        <td
                          style="color: #878686;"
                          @click.prevent="abre_modal_view_caixa(item)"
                        >
                          <span class="fonte fonteMini grey--text">
                            {{ $helper.formataSaldo(parseFloat(ficha.total)) }}
                          </span>
                        </td>
                        <td style="color: #878686; width: 10px;" class="fonte">
                          <input
                            type="checkbox"
                            @click="saveItemDone"
                            v-model="item_e.is_done"
                            class="ml-1"
                            :style="
                              `background-color: ${$theme.primary};cursor: pointer;`
                            "
                          />
                          <checkbox
                              light
                              x-small
                              hide-details
                              v-if="false"
                              :color="$theme.secondary"
                            ></checkbox>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </template>
            </v-simple-table> -->
          </v-flex>
        </div>
      </v-flex>
      <div
        v-else
        style="height: 200px;"
        class="expande-horizontal centraliza pb-6 column"
      >
        <img width="60" src="img/logo_mini.svg" class="mb-3" />

        <!-- <v-icon color="grey" size="64">mdi-cart-check</v-icon> -->
        <h4 class="fonte grey--text">
          Não há produtos nesta compra
        </h4>
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InPreparationStage",
  props: ["nextStatus"],
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  methods: {
    ...mapActions(["atualizar_gestordepedido"]),
    openModalAddProduct() {
      return;
    },
    saveItemDone() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    },
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    }
  }
};
</script>
