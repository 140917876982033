<!-- WebRTCComponent.vue -->
<template>
  <div class="room-container">
    <div class="expande-horizontal centraliza column" v-if="!showLocalVideo" style="max-width: 60%;">
      <LottieAnimation
        class="pa-0"
        ref="anim"
        :loop="true"
        :animationData="require('@/assets/consult-room.json')
        "
      />
      <div v-if="false" class="expande-horizontal centraliza wrap">
        <v-flex xs12 md6 class="px-1">
          <v-select
            filled
            dense
            :items="audioDevices"
            label="Entrada de Audio"
            v-model="audioInput"
            item-text="label"
            item-value="value"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 class="px-1">
          <v-select
            filled
            dense
            :items="videoDevices"
            label="Entrada de Vídeo"
            v-model="videoInput"
            item-text="label"
            item-value="value"
          ></v-select>
        </v-flex>
      </div>
      <v-btn :loading="loading" v-if="
        (get_gestordepedido.status_do_pedido !== 'encerrada' && get_gestordepedido.compra.status !== 'agendada' && getLoggedUser.is_patient)" 
        
        rounded dark :color="$theme.primary" @click="startLocalStream">Chamar o médico
        <v-icon class="ml-2">
          mdi-phone-in-talk-outline
        </v-icon>
      </v-btn>
      <v-btn :loading="loading" v-if="
        (get_gestordepedido.status_do_pedido === 'waiting_patient_accept' && getLoggedUser.is_patient)"
        rounded dark :color="$theme.primary" @click="startLocalStream">Conectar ao médico
        <v-icon class="ml-2">
          mdi-phone-in-talk-outline
        </v-icon>
      </v-btn>
      <v-btn :loading="loading" v-if="liberateToCallClient && !getLoggedUser.is_patient" rounded dark :color="$theme.primary" @click="notifyPatientToEnterInConsult">
        Chamar o paciente
        <v-icon class="ml-2">
          mdi-phone-in-talk-outline
        </v-icon>
      </v-btn>
      <span v-if="error !== ''" class="red--text text-center fonte-bold fonte">
        {{ error }}
      </span>
      <div v-if="get_gestordepedido.compra.status === 'agendada'">
        <span class="fonte fonteMini">A consulta está agendada para o dia {{ get_gestordepedido.compra.disponibility.visit_date}}, no horário de {{ get_gestordepedido.compra.disponibility.visit_hour_start }} às {{ get_gestordepedido.compra.disponibility.visit_hour_end }}</span>
      </div>
    </div>
    <div v-if="remoteStream" class="controls">
      <div class="expande-horizontal centraliza">
        <v-btn small class="mt-3" color="red" @click="closeCall" dark depressed rounded>
          <span class="fonte fonteMini">
            Encerrar
          </span>
          <v-icon size="21" class="ml-2">mdi-phone-remove-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="expande-horizontal centraliza" :class="{ 'column': $vuetify.breakpoint.smAndDown }">
      <video 
        v-if="showLocalVideo" 
        style="border-radius: 6px; max-height: 80%; width: 45%;z-index: 10; top: 10px; left: 0px !important;" 
        class="my-video ma-1" 
        ref="localVideo"
        autoplay
        :playsInline="true"
      >
      </video>
      <video 
        v-if="showRemoteVideo" 
        style="border-radius: 6px; max-height: 80% !important; width: 45%; z-index: 9; right: 0px;" 
        class="my-video ma-1" 
        ref="remoteVideo" 
        :playsInline="true"
        autoplay
      ></video>
      <div v-if="showLocalVideo && !remoteStream" style="max-width: 100%px; background: #333; display: flex; flex-direction: column; border-radius: 6px; height: 450px;">
        <div class="expande-horizontal centraliza column">
          <v-flex xs6>
            <LottieAnimation
              class="pa-0"
              ref="anim"
              :loop="true"
              :speed="0.1"
              :animationData="require('@/assets/searching-doctor.json')
              "
            />
          </v-flex>
        </div>
        <div v-if="!get_gestordepedido.call_client" class="expande-horizontal centraliza">
          <span class="white--text fonteMini">Seu médico está chegando</span>
        </div>
        <div v-else class="expande-horizontal centraliza">
          <span class="white--text fonteMini">Conectando ao paciente</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import LottieAnimation from 'lottie-web-vue';
import { mapGetters } from 'vuex';
  export default {
    components: {
        LottieAnimation
    },
    data() {
      return {
        localStream: null,
        remoteStream: null,
        showLocalVideo: false,
        showRemoteVideo: false,
        localPeer: null,
        remotePeer: null,
        connection: null,
        call: null,
        callStatus: 'waiting',
        error: "",
        loading: false,
        audioDevices: [],
        videoDevices: [],
        videoInput: 'default',
        audioInput: 'default',
        mutedAudio: false,
        mutedVideo: false
      };
    },
    computed: {
      ...mapGetters(['getLoggedUser', 'get_gestordepedido']),
      liberateToCallClient() {
        const consult = this.get_gestordepedido;
        if (consult.compra.status !== 'agendada') return false;
        const now = this.$moment().format();
        const startService = this.$moment(consult.compra.disponibility.visit_date + consult.compra.disponibility.visit_hour_start, 'DD/MM/YYYYHH:mm').format();
        const endService = this.$moment(consult.compra.disponibility.visit_date + consult.compra.disponibility.visit_hour_end, 'DD/MM/YYYYHH:mm').format(); 
        const inConsult = this.$moment(now).isBetween(startService, endService);
        return inConsult;
      }
    },
    methods: {
      initPeer() {
        return new Promise((resolve, reject) => {
          if (!this.localPeer) {
          }
          return resolve(true);
        })
      },
      async initListenNewCall() {
        
      },
      callToPatient() {
        
      },
      callToDoctor() {
        
      },
      muteAudio() {
        this.mutedAudio = true;
        this.localStream.getAudioTracks().forEach(track => {
          track.enabled = false;
        });
      },
      unmuteAudio() {
        this.mutedAudio = false;
        this.localStream.getAudioTracks().forEach(track => {
          track.enabled = true;
        });
      },
      muteVideo() {
        thi.mutedAudio = true;
        this.localStream.getVideoTracks().forEach(track => {
          track.enabled = false;
        })
      },
      unmuteVideo() {
        this.mutedVideo = false;
        this.localStream.getVideoTracks().forEach(track => {
          track.enabled = true;
        })
      },
      listDevices() {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            devices.forEach(device => {
              if (device.kind === 'audiooutput') {
                this.audioDevices.push({
                  label: device.label,
                  value: device.deviceId
                })
              }
              if (device.kind === 'videoinput') {
                this.videoDevices.push({
                  label: device.label,
                  value: device.deviceId
                })
              }
            })
            if (this.videoDevices.length == 1) {
              this.videoInput = this.videoDevices[0].value
            }
          })
      },
      setDefaultAudioDevice() {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            this.audioDevices = devices.filter(device => device.kind === 'audioinput');
          })
      },
      closeCall() {
        if (this.$refs.localVideo) {
          this.$refs.localVideo.srcObject = null;
        }
        if (this.$refs.remoteVideo) {
          this.$refs.remoteVideo.srcObject = null;
        }
        if (this.localStream) {
          this.localStream.getTracks().forEach(track => track.stop());
        }
        if (this.remoteStream) {
          this.remoteStream.getTracks().forEach(track => track.stop());
        }
        this.showLocalVideo = false;
        this.showRemoteVideo = false;
        this.localStream = null;
        this.remoteStream = null;
        if (this.localPeer && this.localPeer.destroy) {
          this.localPeer.destroy();
        }
        if (this.remotePeer && this.remotePeer.destroy) {
          this.remotePeer.destroy();
        }
        this.$run('transacoes/consult-end', { transaction: this.get_gestordepedido }, 'client')
          .then(() => {
            if (this.getLoggedUser.is_patient) {
              this.$router.push("/dashboard")
            } else {
              this.$router.push("/medico")
            }
          })
      },
      async callToPatient(id) {
        this.loading = true;
        const call = this.localPeer.call(id, this.localStream);
        call.on('stream', (stream) => {
          this.showRemoteVideo = true;
          setTimeout(() => {
            this.loading = false;
            this.remoteStream = stream;
            this.$refs.remoteVideo.srcObject = stream;
          }, 1000);
        });
      },
      startRemoteVideo(remoteStream) {
        console.log("starting...")
        return new Promise((resolve, reject) => {
          try {
            this.showRemoteVideo = true;
            setTimeout(() => {
              this.$refs.remoteVideo.srcObject = remoteStream;
              this.remoteStream = remoteStream;
              console.log("remoteStream", remoteStream);
              resolve(true);
            }, 1000);
          } catch (error) {
            reject(error)
          }
        })
      },
      startLocalVideo(localStream) {
        return new Promise((resolve, reject) => {
          try {
            this.showLocalVideo = true;
            setTimeout(() => {
              this.$refs.localVideo.srcObject = localStream;
              this.localStream = localStream;
              resolve(true);
            }, 1000);
          } catch (error) {
            reject(error)
          }
        })
      },
      startLocalStream() {
        return new Promise((resolve, reject) => {
          this.loading = true;
          navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(async stream => {
              await this.startLocalVideo(stream);
              if (!this.get_gestordepedido.call_client && this.getLoggedUser.is_patient) {
                this.$run('transacoes/search-doctor', { specialty: {}, transaction: this.get_gestordepedido }, 'client')
                  .then(() => {
                    this.loading = false;
                    resolve(stream);
                  })
                  .catch(error => {
                    stream.getTracks().forEach(track => track.stop());
                    this.error = error.response.data.error;
                    this.showLocalVideo = false;
                    this.loading = false;
                    reject(error);
                  });
              } else {
                resolve(stream);
              }
            })
            .catch(error => {
              if (error.name === 'NotAllowedError') {
                this.error = 'Você precisa permitir o acesso à câmera e ao microfone para usar este recurso.';
              } else if (error.name === 'NotFoundError') {
                this.error = 'Não foi possível encontrar uma câmera ou um microfone.';
              } else {
                this.error = 'Ocorreu um erro ao tentar acessar sua câmera e microfone.';
              }
              this.loading = false;
              this.showLocalVideo = false;
              reject(error);
            });
        });
      },

      async notifyPatientToEnterInConsult() {
        this.loading = true;
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
          .then(stream => {
            this.showLocalVideo = true;
            this.$run('transacoes/call-patient', { specialty: {}, transaction: this.get_gestordepedido }, 'client')
              .then(() => {
                this.$refs.localVideo.srcObject = stream;
                this.localStream = stream;
                this.loading = false;
              })
              .catch(error => {
                this.error = error.response.data.error;
                this.showLocalVideo = false;
                this.loading = false;
              })
          })
          .catch(error => {
            if (error.name === 'NotAllowedError') {
              this.error = 'Você precisa permitir o acesso à câmera e ao microfone para usar este recurso.'
            } else if (error.name === 'NotFoundError') {
              this.error = 'Não foi possível encontrar uma câmera ou um microfone.';
            } else {
              this.error = 'Ocorreu um erro ao tentar acessar sua câmera e microfone.'
            }
            this.loading = false;
            this.showLocalVideo = false;
          })
      },
      initDataConn(conn) {
        const self = this;
        self.connection = conn;
        self.connection.on('open', () => {
          self.connection.on('data', (data) => {
            console.log('Mensagem recebida:', data);
          });
        });
      },
      showNetworkError(err) {
        console.error('Erro de rede:', err);
      },
      prioritizeCodec(sdp, mediaType, codecName) {
        const sdpLines = sdp.split('\r\n');
        let mediaIndex = -1;
        let codecPayload = -1;

        // Encontre a linha de mídia (m=) e o payload do codec
        for (let i = 0; i < sdpLines.length; i++) {
          if (sdpLines[i].startsWith('m=' + mediaType)) {
            mediaIndex = i;
          }
          if (sdpLines[i].includes(codecName)) {
            const parts = sdpLines[i].split(' ');
            codecPayload = parts[0].split(':')[1];
          }
          if (mediaIndex != -1 && codecPayload != -1) break;
        }

        if (mediaIndex != -1 && codecPayload != -1) {
          const mediaLineParts = sdpLines[mediaIndex].split(' ');
          // Remova o payload do codec de sua posição atual
          const codecIndex = mediaLineParts.indexOf(codecPayload);
          if (codecIndex > -1) {
            mediaLineParts.splice(codecIndex, 1);
          }
          // Adicione o payload do codec imediatamente após o formato de mídia
          mediaLineParts.splice(3, 0, codecPayload);
          sdpLines[mediaIndex] = mediaLineParts.join(' ');
        }

        return sdpLines.join('\r\n');
      }
    },
    beforeDestroy() {
      if (this.localPeer && this.localPeer.destroy) {
        this.localPeer.destroy();
      }
      if (this.remotePeer && this.remotePeer.destroy) {
        this.remotePeer.destroy();
      }
      this.closeCall();
    },
    async mounted() {
      // this.listDevices();
      const self = this;
      const localPeer = new Peer(this.getLoggedUser._id);
      localPeer.on('call', async function(call) {
        if (!self.localStream) await selg.startLocalStream();
        setTimeout(() => {
          call.answer(self.localStream, {
            sdpTransform: function(sdp) {
              sdp = self.prioritizeCodec(sdp, 'audio', 'opus/48000');
              sdp = self.prioritizeCodec(sdp, 'video', 'H264');
              return sdp;
            }
          });
          call.on('stream', async function(stream) {
            console.log("ligação recebida")
            await self.startRemoteVideo(stream)
          })
        }, 1500);
      })
      // this.localPeer.on('open', (id) => {
      //   console.log('My peer ID is: ' + id);
      // });
      //nova conexão de dados
      // this.localPeer.on('connection', function(conn) {
      //   self.initDataConn(conn);
      // });
      localPeer.on('close', function() {
        self.closeCall();
      });
      localPeer.on('disconnected', function() {
        self.showNetworkError();
      });
      localPeer.on('error', function(err) {
        self.showNetworkError(err);
      });
      if (this.get_gestordepedido.call_client) {
        await this.startLocalStream();
        const call = localPeer.call(self.get_gestordepedido.compra.cliente._id, self.localStream);
        call.on('stream', async function (stream) {
          await self.startRemoteVideo(stream)
        })
      }
      if (this.get_gestordepedido.call_doctor) {
        await this.startLocalStream();
        const call = localPeer.call(self.get_gestordepedido.compra.doctor._id, self.localStream);
        call.on('stream', async function(stream) {
          await self.startRemoteVideo(stream)
        })
      }
      this.localPeer = localPeer;
    }
  };
  </script>
  
  <style scoped lang="scss">
  .room-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 72vh;
    background: #f7f7f7;
    position: relative;
    overflow: hidden;
  }
  .room-container:hover {
    .controls {
      visibility: visible;
      bottom: 0px !important;

    }
  }
  .controls {
    position: absolute;
    display: flex;
    bottom: -60px !important;
    width: 100%;
    visibility: hidden;
    left: 0;
    right: 0;
    z-index: 12;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(138, 134, 134, 0.7)) !important;
    color: white;
    padding: 5px;
    max-height: 60px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    transition: bottom 0.2s ease;
  }
  .my-video {
    width: 190px;
    border-radius: 6px;
  }
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .my-video:hover {
    .controls {
        
    }
  }
  </style>
  