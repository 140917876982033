<template>
  <v-dialog persistent width="600" v-model="opened">
    <v-flex class="fonte" xs12>
      <v-card style="border-radius: 12px;">
        <v-flex xs12>
          <div class="windows-style" style="border-top-right-radius: 12px; border-top-right-radius: 12px;">
            <div class="expande-horizontal">
              <v-flex xs12>
                <v-list color="transparent" dense nav>
                  <!-- Escolher especialidade -->
                  <v-list-item v-if="step == '0'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">Confirmar liberação</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">Clique abaixo para confirmar a liberação do retorno ao paciente</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="step == '1'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte"> Tudo certo </span>
                      </v-list-item-title>
                      <span class="fonte grey--text">próximos passos</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
          </div>
        </v-flex>
        <div class="pa-3 windows-style-content">
          <v-window v-model="step">
            <!-- liberar o retorno ao paciente -->
            <v-window-item value="0">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal fonte centraliza">
                    <v-chip 
                      @click="confirmReturnRequest" 
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2">mdi-checkbox-blank-circle-outline</v-icon>
                      Confirmar liberação de retorno
                    </v-chip>
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <v-window-item value="1">
              <div class="expande-horizontal wrap">
                <v-flex v-if="loading" class="pa-3" xs12>
                  <div class="expande-horizontal py-6 centraliza column">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <span class="text-center px-6 mt-3">Confirmando liberação de retorno.</span>
                  </div>
                </v-flex>
                <v-flex v-else class="pa-3" xs12>
                  <div class="expande-horizontal py-6 centraliza column">
                    <v-icon class="ma-12" size="40">
                      mdi-check-circle
                    </v-icon>
                    <span class="text-center px-6 mt-3">O retorno está cadastrado para o paciente.</span>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn rounded class="mr-2" color="green" dark @click="finalize">Sair </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';
import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters(["get_gestordepedido"])
    },
    components: {
      LottieAnimation,
    },
    methods: {
      confirmReturnRequest() {
        this.step = '1'
        this.loading = true;
        this.$run('transacoes/request-return',{
          transaction_id: this.get_gestordepedido._id,
        })
        .then((res) => {
          this.loading = false;
          this.$store.commit('set_gestordepedido', res.data);
        })
        .catch((err) => {
          this.step = '0'
          this.loading = false;
          this.$store.dispatch('createGlobalMessage', {
            type: "error",
            timeout: 5000,
            message: "Erro ao solicitar retorno, tente novamente!",
          })
        })
      },
      finalize() {
        this.opened = false
        this.step = '0'
      },
      open() {
        this.opened = true
      }
    },
    data() {
      return {
        anexo_encaminhamento: [],
        specialties: [],
        specialty: {},
        doctors: [],
        doctor: {},
        loading: false,
        disponibilities: [],
        disponibility: {},
        opened: false,
        step: '0',
        files: [],
        triage: {
          //1
          encaminhamento: {
            title: 'Você tem encaminhamanto?',
            description: '',
            response: "",
          },
          //2
          anexar_encaminhamento: {
            title: "Anexe o encaminhamento",
            description: "Clique no botão abaixo e anexe o encaminhamento?",
            response: []
          },
          //3
          nao_tem_encaminhamento: {
            title: "Clínico Geral",
            description: "Como você ainda não possui encaminhamento, você precisa se consultar primeiro com um clínico geral",
            options: []
          },
          escolher_especialidade: {
            title: "Escolha a especialidade",
            description: "Veja as especialidades disponíveis abaixo",
            options: []
          },
          escolher_especialista: {
            title: "Escolha o médico disponível",
            description: "Veja os profissionais e horários disponíveis abaixo",
            options: []
          },
          escolher_horario: {
            title: "Escolha o dia da consulta",
            description: "Veja os profissionais e horários disponíveis abaixo",
            especialidade: {},
            horario: {},
          },
        }
      }
    }
  }
</script>