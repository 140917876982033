<template lang="pug">
v-dialog(v-model="opened", width="500")
  v-flex.vitrify(xs12, v-if="opened" style="min-height: 10vh; background: rgba(255,255,255,0.8)")
    div.expande-horizontal.wrap.pt-0
      div.expande-horizontal.windows-style.fonte
        v-flex(xs12)
          v-list.px-0.mx-0(nav, dense, color="transparent")
            v-list-item 
              v-avatar.mr-3(color="#333")
                v-icon(:color="$theme.primary") mdi-map-search-outline
              v-list-item-content
                v-list-item-title.fonte.text-xs-center
                  span.fonte Endereço
                v-list-item-subtitle.fonte
                  span.fonte Busque o endereço pelo CEP
              v-list-item-action
                v-avatar(size="31",:color="$theme.primary")
                  v-btn(
                    @click="close"
                    color="#333"
                    text
                    small
                    fonte
                  )
                    v-icon mdi-close
      v-flex(xs12).pa-6.pt-6.windows-style-content
        div.card-method-form.expande-horizontal.wrap.fonte
          v-flex(xs12)
            v-form(ref="payer_form")
              v-flex(xs7)
                v-text-field(
                  dense
                  label="CEP",
                  v-model="get_gestordepedido.compra.address.zip_code"
                  :color="$theme.primary"
                  filled,
                  type="number"
                  ref="cepInput"
                  @input="searchCep"
                  :rules="cepRules"
                  v-mask="['########']"
                  placeholder="########"
                ) 
              div.expande-horizontal
                v-flex(xs9).pr-3
                  v-text-field(
                    dense
                    v-model="get_gestordepedido.compra.address.street"
                    :color="$theme.primary"
                    label="Endereço",
                    filled
                  ) 
                v-flex(xs3)
                  v-text-field(
                    dense
                    v-model="get_gestordepedido.compra.address.number"
                    label="Nº",
                    ref="addressNumber"
                    @keyup.enter.prevent="saveAddress"
                    :color="$theme.primary"
                    filled
                  ) 
              v-flex(xs12)
                div.expande-horizontal
                  v-flex.mr-3(xs4)
                    v-text-field(
                      v-model="get_gestordepedido.compra.address.city"
                      dense,
                      label="Cidade",
                      placeholder="ex: Macapá"
                      :color="$theme.primary"
                      filled
                    ) 
                  v-flex.mr-3(xs4)
                    v-text-field(
                      v-model="get_gestordepedido.compra.address.state"
                      dense,
                      label="Estado",
                      placeholder="ex: Amapá"
                      :color="$theme.primary"
                      filled
                    ) 
                  v-flex(xs6)
                    v-text-field(
                      v-model="get_gestordepedido.compra.address.district"
                      dense,
                      label="Bairro",
                      placeholder="ex: Zerão"
                      :color="$theme.primary"
                      filled
                    ) 
          v-flex(xs12)
            v-btn.fonte.elevation-0(rounded,dark,@click="saveAddress",:color="$theme.primary") 
              span.black--text Emitir nota        

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["printDeliveryNote"],
  data() {
    return {
      cepRules: [v => !!v || "preencha seu cep"],
      opened: false
    };
  },
  computed: {
    ...mapGetters(["get_gestordepedido", "get_clientes"])
  },
  methods: {
    ...mapActions(["get_cep"]),
    async searchCep() {
      if (this.get_gestordepedido.compra.address.zip_code) {
        if (this.get_gestordepedido.compra.address.zip_code.length === 8) {
          const res = await axios.get(
            `https://viacep.com.br/ws/${this.get_gestordepedido.compra.address.zip_code}/json/`
          );
          if (res.status === 200) {
            this.get_gestordepedido.compra.address.street = res.data.logradouro;
            this.get_gestordepedido.compra.address.district = res.data.bairro;
            this.get_gestordepedido.compra.address.city = res.data.localidade;
            this.get_gestordepedido.compra.address.state = res.data.uf;
            this.get_gestordepedido.compra.address.bairro = res.data.bairro;
            this.get_gestordepedido.compra.address.range_numbers =
              res.data.complemento;
            this.get_gestordepedido.compra.address.number = "";
            this.$refs.addressNumber.focus();
            this.$forceUpdate();
          }
        }
      }
    },
    saveAddress() {
      if (this.$refs.payer_form.validate()) {
        // const address = this.get_gestordepedido.compra.address;
        this.close();
        this.printDeliveryNote(this.get_gestordepedido);
      }
    },
    open() {
      if (!this.get_gestordepedido.compra.address) {
        this.get_gestordepedido.compra.address = {};
      }
      this.opened = true;
      setTimeout(() => {
        this.$refs.cepInput.focus();
      }, 300);
    },
    close() {
      this.opened = false;
      setTimeout(() => {
        this.$forceUpdate();
      }, 1000);
    }
  },
  created() {}
};
</script>

<style scoped>
.card-method-card {
  max-width: 300px;
}
.card-title {
  color: #363636;
}
.card-container {
  width: 100%;
  display: flex;
  background: #f0f0f0;
  border: 1px solid #0da8f0;
  border-radius: 10px;
}
</style>
