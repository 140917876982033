<template>
  <v-flex xs12 md4 class="px-2 fonte">
    <v-text-field
      placeholder="Faça uma busca"
      v-model="filter.search"
      dense
      class="fonte fonteMini font-weight-light"
      hide-details
      :color="$theme.primary"
      @input="debounceSearch"
    ></v-text-field>
  </v-flex>
  <!-- <CloseDelivery :is_delivery="filter.type === 'delivery' ? true : false" /> -->
</template>
<script>
// import CloseDelivery from "../components/CloseDeliveryTemporary";
var _ = require("lodash");

export default {
  props: ["filter"],
  // components: { CloseDelivery },
  methods: {
    debounceSearch() {
      this.$forceUpdate();
    }
  }
};
</script>
