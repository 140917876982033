<template>
  <v-flex v-if="payments.length > 0" xs12>
    <div class="wrap expande-horizontal pa-2">
      <v-flex xs12>
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-flex xs12>
              <v-list nav dense class="px-0 mx-0" color="transparent">
                <v-list-item class="px-0 fonte mx-0">
                  <v-avatar class="mr-2" size="31" color="#333">
                    <v-icon size="20" :color="$theme.primary"
                      >mdi-currency-usd</v-icon
                    >
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Pagamentos
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Veja abaixo comentários e movimentações
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action> </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </div>
        </v-flex>

        <div
          :class="{ 'pl-7': $vuetify.breakpoint.lgAndUp }"
          class="expande-horizontal wrap pt-2 mb-2"
        >
          <v-flex xs12>
            <v-list color="transparent" dense>
              <v-list-item
                class="fonte"
                style="margin-bottom:6px; background: #EBEBEB; border-radius: 6px;"
                :key="`pagamento-${i}`"
                v-show="pagamento.valor"
                v-for="(pagamento, i) in payments"
              >
                <!-- <v-avatar size="21" class="mr-2" color="#ebebeb">
                  <v-icon
                    size="19"
                    :color="$theme.secondary"
                    ></v-icon
                  >
                </v-avatar> -->
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $helper.formataSaldo(pagamento.valor) }} em
                    {{ pagamento.forma_de_pagamento }}
                  </v-list-item-title>
                  <span class="grey--text fonteMini">
                    Data:
                    {{ $moment(pagamento.data).format("DD/MM/YY [às] HH:mm") }}
                  </span>
                  <span class="fonteMini signed-gradient">
                    {{ pagamento.sign }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    payments: {
      type: Array,
      default: () => []
    }
  }
};
</script>
